import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import RichTextMarkdown from "../utils/RichTextMarkdown";
import {PhotoshopPicker} from "react-color";
import {Modal} from "reactstrap";
import {validateEmail} from "../utils/HelpfulFunction";

class SetUpTicketEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            emailVariables: '',
            loading: true,
            showTabOneemailComponents: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        this.emailVariablesRef = base.listenTo(`emailVariables`, {
            context: this,
            state: 'emailVariables',
            then(data){
                let advanced = false;
                if(data && data.sender){
                    advanced = true;
                }
                this.setState({
                    loading: false,
                    advanced: advanced,
                    emailVariables: data
                })
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.emailVariablesRef);
    }

    handleChange(event){
        let target = event.target;
        let value = target.value;
        if(target.type === 'checkbox'){
            value = target.checked;
            this.setState({[target.name]: value})
        } else {
            let emailVariablesCopy = this.state.emailVariables;
            emailVariablesCopy[event.target.name] = value;
            this.setState({ emailVariables: emailVariablesCopy });
        }
    }

    handleImageChange(event){
        const name_of_file = event.target.name;
        const target = this[name_of_file];
        const file_to_update = target.files[0];
        this.setState({loading:true});
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({loading:false});
            if(res.error){
                swal({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else if(res.imageUrl) {
                let emailVariablesCopy = vm.state.emailVariables;
                emailVariablesCopy[name_of_file] = res.imageUrl;
                vm.setState({
                    emailVariables: emailVariablesCopy,
                }, () => {
                    vm[name_of_file].value = "";
                });
            } else {
                swal({
                    title: 'Image cannot be uploaded',
                    text: "Something went wrong, please re-upload your image and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        });
    };

    acceptPicker(){
        const emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy[this.state.colorToUpdate] = this.state.colorToShow;
        this.setState({
            emailVariables: emailVariablesCopy,
            updateHappend: true,
            colorPickerShow: false,
        });
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        });
    }

    onChangeHelpText = (helpText) => {
        let emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy["helpText"] = helpText;
        this.setState({ emailVariables: emailVariablesCopy });
    }

    onChangeLosingHelpText = (losingHelpText) => {
        let emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy["losingHelpText"] = losingHelpText;
        this.setState({ emailVariables: emailVariablesCopy });
    }

    handleSubmit(event) {
        event.preventDefault();
        const emailVariables = this.state.emailVariables;
        let sender = emailVariables.sender || "";
        if (sender && !validateEmail(sender)) {
            swal({
                title: 'Invalid Sender Email!',
                text: 'Please make sure the sender contains a valid email address.',
                type: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        let subjectLine = emailVariables.subjectLine || "";
        let preview = emailVariables.preview || "";
        let helpText = emailVariables.helpText || "";
        let loserImageLink = emailVariables.loserImageLink || "";
        let loserSubjectLine = emailVariables.loserSubjectLine || "";
        let losingHelpText = emailVariables.losingHelpText || "";
        let backgroundImage = emailVariables.emailBackgroundImage || "";
        let losingEmailImage = emailVariables.losingEmailImage || "";
        let vendorName = emailVariables.vendorName || "";
        let buttonActionText = emailVariables.buttonActionText || "";
        let templateText = emailVariables.templateText || "";
        let dunkinEmailButtonText = emailVariables.dunkinEmailButtonText || "";
        let dunkinEmailButtonColor = emailVariables.dunkinEmailButtonColor || "";
        let dunkinEmailButtonTextColor = emailVariables.dunkinEmailButtonTextColor || "";

        this.setState({loading:true});
        const updateRulesObject = {};
        updateRulesObject['helpText'] = helpText;
        updateRulesObject['subjectLine'] = subjectLine;
        updateRulesObject['emailBackgroundImage'] = backgroundImage;
        updateRulesObject['sender'] = sender;
        updateRulesObject['loserSubjectLine'] = loserSubjectLine;
        updateRulesObject['loserImageLink'] = loserImageLink;
        updateRulesObject['losingHelpText'] = losingHelpText;
        updateRulesObject['losingEmailImage'] = losingEmailImage;
        updateRulesObject['templateText'] = templateText;
        updateRulesObject['vendorName'] = vendorName;
        updateRulesObject['preview'] = preview;
        updateRulesObject['buttonActionText'] = buttonActionText;
        updateRulesObject['dunkinEmailButtonText'] = dunkinEmailButtonText;
        updateRulesObject['dunkinEmailButtonColor'] = dunkinEmailButtonColor;
        updateRulesObject['dunkinEmailButtonTextColor'] = dunkinEmailButtonTextColor;

        const vm = this;
        base.post("emailVariables/", {
            data: updateRulesObject,
            then(err){
                vm.setState({loading:false});
                if(!err){
                    swal({
                        title: 'Variables Updated!',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    });
                } else {
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            }
        });
    }

    tripleToggleControl(element, whichToggle){
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        const tabThree = "showTabThree" + whichToggle;
        const firstElement = document.getElementById('showfirst' + whichToggle);
        const secondElement = document.getElementById('showsecond' + whichToggle);
        const thirdElement = document.getElementById('showthird' + whichToggle);
        if(element === "first"){
            firstElement.classList.add('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: true,
                [tabTwo]: false,
                [tabThree]: false,
            });
        }else if(element === "second"){
            firstElement.classList.remove('active');
            secondElement.classList.add('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: true,
                [tabThree]: false,
            });
        } else {
            firstElement.classList.remove('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.add('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: false,
                [tabThree]: true,
            });
        }
    }

    render() {
        let sender = this.state.emailVariables.sender || "";
        let subjectLine = this.state.emailVariables.subjectLine || "";
        let preview = this.state.emailVariables.preview || "";
        let helpText = this.state.emailVariables.helpText || "";
        let emailBackgroundImage = this.state.emailVariables.emailBackgroundImage || "";

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
                <SideMenu/>
                <TopMenu/>
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{marginBottom:0}}>Email Branding</p>
                    <p className="admin-subheader-text">This is where you can edit your communication sent to fans</p>
                    <div className="container-out">
                        <div className="admin-form-box">
                            <form onSubmit={this.handleSubmit} id="create-email-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:10}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                                <div className="mb-3">
                                    <label htmlFor="subjectLine" className="form-label">Email Subject Line</label>
                                    <div className="form-text">This is the subject line that your fans will see when they receive their messages</div>
                                    <input id="subjectLine" name="subjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="preview" className="form-label">Email Preview Text</label>
                                    <div className="form-text">This is the preview text for the email, if left blank it will be replaced by the subject line. For mobile optimization the length should be between 35-50 characters but for desktop the preview can be up to 140 characters and beyond</div>
                                    <input id="preview" name="preview" className="form-control" value={preview} onChange={this.handleChange} />
                                </div>
                                <div className="mb-3">
                                    <RichTextMarkdown
                                        field={{
                                            id: "helpText",
                                            name: "helpText",
                                            value: helpText
                                        }}
                                        form={{
                                            setFieldValue: (field, value) => this.onChangeHelpText(value)
                                        }}
                                        label="Help Text"
                                        sublabel="This is where you can tell fans where to contact you with issues."
                                    />
                                </div>
                                <div className="mb-3" align="center">
                                    <img src={emailBackgroundImage} width="auto" height="100px" alt=""/>
                                    <br/>
                                    <label htmlFor="backgroundImage" className="form-label">Email Header Image<br/>(700px X 400px)</label>
                                    <div className="mb-3">
                                        <input style={{display:'none'}}  id="emailBackgroundImage" name="emailBackgroundImage" type="file" ref={input => {this.emailBackgroundImage = input; }} onChange={this.handleImageChange}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('emailBackgroundImage').click()} />
                                    </div>
                                </div>
                                <div className="mb-3 form-check">
                                    <input className="form-check-input" type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                    <label htmlFor="showAdvancedSettings" className="form-check-label">Advanced</label>
                                </div>
                                {this.state.advanced &&
                                    <div className="mb-3">
                                        <label htmlFor="sender" className="form-label">Email Sender</label>
                                        <div className="form-text">This is the sender your fans will see when they receive their winning emails. BE AWARE: changing the sender could adversely impact delivery rates</div>
                                        <input id="sender" name="sender" type="text" className="form-control" value={sender} onChange={this.handleChange} />
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                    <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
                </Modal>
            </div>
        );
    }
}

export default SetUpTicketEmail;
