import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { Modal } from 'reactstrap';
import UploadImage from '../utils/UploadImage';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import { PhotoshopPicker } from 'react-color';
import RichTextMarkdown from '../utils/RichTextMarkdown';
import {isEmptyHtmlEntity} from "../utils/HelpfulFunction";

class SetUpTeamVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOneMajor: true,
            showTabTwoMajor: false,
            showTabThreeMajor: false,
            showTabOneMinor: true,
            showTabTwoMinor: false,
            showTabThreeMinor: false,
            loading: true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            advanced: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.createFormResponse = this.createFormResponse.bind(this);
    }

    async componentDidMount() {
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then() {
                const tenantVariables = this.state.tenantVariables;
                let advanced = false;
                if (tenantVariables && tenantVariables.formHeaderText) {
                    advanced = true;
                }
                if (tenantVariables) {
                    this.setState({
                        tenantVariables: tenantVariables,
                        advanced: advanced
                    })
                }
            }
        });
        this.formQuestionsRef = base.bindToState('formQuestions', {
            context: this,
            asArray: true,
            state: 'formQuestions'
        });
        this.setState({
            loading: false,
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.tenantVariablesRef);
        base.removeBinding(this.formQuestionsRef);
    }

    handleSubmit(event) {
        event.preventDefault();
        const tenantVariables = { ...this.state.tenantVariables };

        const fieldsToUpdate = [
            'holdingBody', 'playingBody', 'winningMessage', 'losingMessage',
            'aboveFormText', 'aboveSubmitButtonText', 'belowSubmitButtonText'
        ];

        fieldsToUpdate.forEach(field => {
            let content = tenantVariables[field] || "";
            if (isEmptyHtmlEntity(content)) {
                content = "";
            }
            tenantVariables[field] = content;
        });

        if (!this.state.advanced) {
            tenantVariables["formHeaderText"] = "";
            tenantVariables["otherResponse"] = false;
        }

        this.setState({ loading: true });
        base.post("tenantVariables/", {
            data: tenantVariables,
            then: (err) => {
                this.setState({ loading: false });
                if (!err) {
                    swal({
                        title: 'Tenant Variables Updated!',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    });
                } else {
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            }
        });
    }

    handleChange(event){
        let target = event.target;
        let value = target.value;
        if(target.type === 'checkbox'){
            value = target.checked;
            let tenantVariablesCopy = this.state.tenantVariables;
            tenantVariablesCopy[target.name] = value
            this.setState({ tenantVariables:  tenantVariablesCopy});
        } else if(target.name === "formQuestionText") {
            this.setState({[target.name]: value})
        } else {
            let tenantVariablesCopy = this.state.tenantVariables;
            tenantVariablesCopy[target.name] = value
            this.setState({ tenantVariables:  tenantVariablesCopy});
        }
    }

    handleRichTextChange = (name, text) => {
        this.setState(prevState => ({
            tenantVariables: {
                ...prevState.tenantVariables,
                [name]: text
            }
        }));
    }

    handleImageChange(event) {
        const name_of_file = event.target.name;
        const target = this[name_of_file];
        const file_to_update = target.files[0];
        this.setState({ loading: true });
        this.UploadImage.upload_file(file_to_update).then(res => {
            this.setState({ loading: false });
            if (res.error) {
                swal({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else if (res.imageUrl) {
                this.setState(prevState => ({
                    tenantVariables: {
                        ...prevState.tenantVariables,
                        [name_of_file]: res.imageUrl
                    }
                }), () => {
                    this[name_of_file].value = ""
                });
            } else {
                swal({
                    title: 'Image cannot be uploaded',
                    text: 'There was some issue with the image upload, please check the image type and size and try again',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
    }

    createFormResponse() {
        const formResponse = this.state.formQuestionText || "";
        if (!formResponse) {
            swal({
                title: "Missing Text",
                text: 'Please make sure to add some text in the above input',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        const formResponseId = appDatabasePrimaryFunctions.ref().push().key;
        this.setState({ loading: true });
        const objectToSave = {
            id: formResponseId,
            text: formResponse
        };
        base.post('formQuestions/' + formResponseId, {
            data: objectToSave,
            then: (err) => {
                this.setState({ loading: false });
                if (err) {
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            }
        });
    }

    removeFormQuestion(itemId) {
        base.remove(`formQuestions/${itemId}`, function (err) {
            if (err) {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        });
    }

    handleCheckBoxChange(evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    openColorPicker(colorToChange){
        const oldColor = this.state.tenantVariables[colorToChange];
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        const tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[this.state.colorToUpdate] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariablesCopy,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    toggleColors(){
        if(this.state.colorsTab){
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState({colorsTab: !this.state.colorsTab})
    }

    tripleToggleControl(element, whichToggle){
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        const tabThree = "showTabThree" + whichToggle;
        const firstElement = document.getElementById('showfirst' + whichToggle);
        const secondElement = document.getElementById('showsecond' + whichToggle);
        const thirdElement = document.getElementById('showthird' + whichToggle);
        if(element === "first"){
            firstElement.classList.add('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: true,
                [tabTwo]: false,
                [tabThree]: false,
            })
        }else if(element === "second"){
            firstElement.classList.remove('active');
            secondElement.classList.add('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: true,
                [tabThree]: false,
            })
        } else {
            firstElement.classList.remove('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.add('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: false,
                [tabThree]: true,
            })
        }
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{ marginBottom: 0, marginTop: '5px' }}>Game Branding</p>
                    <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                    <div className="container-out">
                        <div className="admin-form-box" style={{ marginTop: '0px', paddingTop: '5px' }}>
                            <form onSubmit={this.handleSubmit} id="create-game-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: '20px' }}>
                                    <span className="fa fa-arrow-circle-o-up" /> Update
                                </button>
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                    <li className="nav-item" onClick={() => this.tripleToggleControl('first', 'Major')}>
                                        <a href="#" aria-expanded="false" className="nav-link active" id="showfirstMajor">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Fan Color/Images</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl('second', 'Major')}>
                                        <a href="#" aria-expanded="true" className="nav-link" id="showsecondMajor">
                                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Game Text</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabOneMajor === true ? '' : 'none'}}>
                                    <li className="nav-item" onClick={()=> this.toggleColors()}>
                                        <a href="#" aria-expanded="false" className="nav-link active" id="showColors">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                            <span className="d-none d-lg-block">Colors</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={()=> this.toggleColors()}>
                                        <a href="#" aria-expanded="true" className="nav-link" id="showImages">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                            <span className="d-none d-lg-block">Images</span>
                                        </a>
                                    </li>
                                </ul>
                                <div style={{display: this.state.showTabOneMajor ? 'block' : 'none'}}>
                                    <div style={{display: this.state.colorsTab ? 'block': 'none'}}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Button Colors</h4>
                                                <div className="form-inline">
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                                                        <div style={{backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, minWidth: 20, display: "inline-block"}}/>
                                                        <label htmlFor="primaryColor" style={{marginRight: 10}}>Color</label>
                                                        <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                                    </div>

                                                    <div style={{height:'10px', width:'100%'}}/>

                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                                                        <div style={{backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, minWidth: 20, display: "inline-block"}}/>
                                                        <label htmlFor="secondaryColor" style={{marginRight: 10}}>Text Color</label>
                                                        <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Logout Color</h4>
                                                <div className="form-inline">
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("logOutButtonColor")}/>
                                                        <div style={{backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, minWidth: 20, display: "inline-block"}}/>
                                                        <label htmlFor="logOutButtonColor" style={{marginRight: 10}}>Color</label>
                                                        <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display: this.state.colorsTab ? 'none': 'block'}}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="admin-grid-container three-columns" style={{display: "flex",  alignItems: 'flex-end', justifyContent: 'space-evenly'}}>
                                                    <div className="form-group" style={{textAlign:'center', margin:20}}>
                                                        <img src={this.state.tenantVariables.backgroundImage} width="160" height="auto" alt=""/>
                                                        <br/>
                                                        <label htmlFor="backgroundImage">Phone Background Image<br/>(750px X 1334px)</label>
                                                        <div className="form-group">
                                                            <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onChange={this.handleImageChange}/>
                                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group" style={{textAlign:'center', margin:20}}>
                                                        <img src={this.state.tenantVariables.frontLogoImage} width="150" height="auto" alt=""/>
                                                        <br/>
                                                        <label htmlFor="frontLogoImage">Front Logo<br/>(700px X 700px)</label>
                                                        <div className="form-group">
                                                            <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onChange={this.handleImageChange}/>
                                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                                                        <img src={this.state.tenantVariables.faviconImage} width="100" height="auto" alt=""/>
                                                        <br/>
                                                        <label htmlFor="faviconImage">Favicon Image (Optional)<br/>(48px X 48px)</label>
                                                        <div className="form-group">
                                                            <input style={{display:'none'}} id="faviconImage" name="faviconImage" type="file" ref={input => {this.faviconImage = input; }} onChange={this.handleImageChange}/>
                                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('faviconImage').click()} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: this.state.showTabTwoMajor ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <label htmlFor="pageTitle">Page Title:</label>
                                        <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Game" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="holdingHeader">Header Holding Text:</label>
                                        <input id="holdingHeader" name="holdingHeader" type="text" className="form-control" value={tenantVariables.holdingHeader} onChange={this.handleChange} placeholder="The next game will start shortly" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="holdingBody">Body Holding Text:</label>
                                        <RichTextMarkdown
                                            field={{
                                                id: "holdingBody",
                                                name: "holdingBody",
                                                value: tenantVariables.holdingBody || ""
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('holdingBody', value)
                                            }}
                                            placeholder="Enter holding text"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="playingHeader">Header Playing Text:</label>
                                        <input id="playingHeader" name="playingHeader" type="text" className="form-control" value={tenantVariables.playingHeader} onChange={this.handleChange} placeholder="Status: Registered" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="playingBody">Body Playing Text:</label>
                                        <RichTextMarkdown
                                            field={{
                                                id: "playingBody",
                                                name: "playingBody",
                                                value: tenantVariables.playingBody || ""
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('playingBody', value)
                                            }}
                                            placeholder="Enter playing text"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="winningHeader">Winning Header</label>
                                        <input id="winningHeader" name="winningHeader" type="text" className="form-control" value={tenantVariables.winningHeader} onChange={this.handleChange} placeholder="You Win!" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="winningMessage">Winning Message</label>
                                        <RichTextMarkdown
                                            field={{
                                                id: "winningMessage",
                                                name: "winningMessage",
                                                value: tenantVariables.winningMessage || ""
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('winningMessage', value)
                                            }}
                                            placeholder="Enter winning message"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="losingHeader">No Prize Header</label>
                                        <input id="losingHeader" name="losingHeader" type="text" className="form-control" value={tenantVariables.losingHeader} onChange={this.handleChange} placeholder="No prize won" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="losingMessage">No Prize Message</label>
                                        <RichTextMarkdown
                                            field={{
                                                id: "losingMessage",
                                                name: "losingMessage",
                                                value: tenantVariables.losingMessage || ""
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('losingMessage', value)
                                            }}
                                            placeholder="Enter no prize message"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="loginFormHeader">Login Form Header</label>
                                        <RichTextMarkdown
                                            field={{
                                                id: "loginFormHeader",
                                                name: "loginFormHeader",
                                                value: tenantVariables.loginFormHeader || ""
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('loginFormHeader', value)
                                            }}
                                            placeholder=""
                                        />
                                        {/*<input id="loginFormHeader" name="loginFormHeader" type="text" className="form-control" value={tenantVariables.loginFormHeader} onChange={this.handleChange} placeholder="" />*/}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="aboveFormText">Above Form Text</label>
                                        <RichTextMarkdown
                                            field={{
                                                id: "aboveFormText",
                                                name: "aboveFormText",
                                                value: tenantVariables.aboveFormText || ""
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('aboveFormText', value)
                                            }}
                                            placeholder="Enter text above the form"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="aboveSubmitButtonText">Above Submit Button Text</label>
                                        <RichTextMarkdown
                                            field={{
                                                id: "aboveSubmitButtonText",
                                                name: "aboveSubmitButtonText",
                                                value: tenantVariables.aboveSubmitButtonText || ""
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('aboveSubmitButtonText', value)
                                            }}
                                            placeholder="Enter text above the submit button"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="belowSubmitButtonText">Below Submit Button Text</label>
                                        <RichTextMarkdown
                                            field={{
                                                id: "belowSubmitButtonText",
                                                name: "belowSubmitButtonText",
                                                value: tenantVariables.belowSubmitButtonText || ""
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('belowSubmitButtonText', value)
                                            }}
                                            placeholder="Enter text below the submit button"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="showAdvancedSettings">Advanced</label>
                                        <br />
                                        <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleCheckBoxChange} />
                                    </div>
                                    {this.state.advanced &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="formHeaderText">Form header Text:</label>
                                                <input id="formHeaderText" name="formHeaderText" type="text" className="form-control" value={tenantVariables.formHeaderText} onChange={this.handleChange} placeholder="Fill out the form for a chance to win a prize!" />
                                            </div>
                                            <h3>Add a response</h3>
                                            <div className="form-group">
                                                <label htmlFor="formQuestionText">Form response Text:</label>
                                                <input id="formQuestionText" name="formQuestionText" type="text" className="form-control" value={this.state.formQuestionText} onChange={this.handleChange} placeholder="A option on form" />
                                            </div>
                                            <button onClick={() => this.createFormResponse()} type="button" className="btn btn-primary btn-lg update-button" id="addFormQuestion" style={{ marginBottom: '20px' }}>
                                                <span className="fa fa-arrow-circle-o-up" /> Add Form Response
                                            </button>
                                            <div className="form-group">
                                                <label htmlFor="otherResponse">Add an OTHER response checkbox</label>
                                                <br />
                                                <input type="checkbox" checked={tenantVariables.otherResponse} id="otherResponse" name="otherResponse" onChange={this.handleChange} />
                                            </div>
                                            <h3>Responses On Form</h3>
                                            {this.state.formQuestions && this.state.formQuestions.length > 0 &&
                                                this.state.formQuestions.map((item, i) => (
                                                    <div key={i}>
                                                        {item.text} <span style={{ cursor: "pointer" }} onClick={() => this.removeFormQuestion(item.id)}>❌</span>
                                                    </div>
                                                ))
                                            }
                                            {(!this.state.formQuestions || this.state.formQuestions.length === 0) &&
                                                <h5>No Questions Added!</h5>
                                            }
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                    <PhotoshopPicker onChangeComplete={this.handleChangeComplete} color={this.state.colorToShow} onAccept={() => this.acceptPicker()} onCancel={() => this.dismissPicker()} />
                </Modal>
            </div>
        );
    }
}

export default SetUpTeamVariables;
